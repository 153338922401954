/** @format */

import React, { useEffect, useState } from 'react';
import carnivalLogo from './assets/images/carnival.png';
import carnivalLogo2x from './assets/images/carnival@2x.png';

const Preloader = () => {
	const [isLoading, setIsLoading] = useState(true);

	let isMobile =
		window.matchMedia('(max-width: 992px)').matches ||
		window.matchMedia('(pointer: coarse)').matches;

	const preloadBKG = !isMobile
		? require(`./assets/images/LoadScreen.jpg`)
		: require(`./assets/images/LoadScreen-M.jpg`);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 3000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<aside
			aria-live="polite"
			aria-label="Do not refresh the page"
			id="preloader"
			style={{
				backgroundImage: `url(${preloadBKG})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
			}}
			className={
				'h-screen w-full absolute top-0 left-0 bg-carnival-blue flex flex-col items-center pt-[200px] md:pt-0 md:justify-center z-[100] overflow-x-hidden ' +
				(isLoading ? 'opacity-100' : 'opacity-0 pointer-events-none')
			}
		>
			<img
				className={'flex-none'}
				src={carnivalLogo}
				alt="Carnival logo"
				srcSet={carnivalLogo2x + ' 2x'}
				style={{ width: '153px', height: '37px' }}
			/>
			<div id="progressbar">
				<span></span>
			</div>
		</aside>
	);
};

export default Preloader;
