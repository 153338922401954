/** @format */

import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { triggerGAEvent } from '../helpers';

import ToggleSwitch from './ToggleSwitch';

import iconAD from '../assets/images/icon-AD.png';
import mainPosterVideo from '../assets/Jubilee-Poster.jpg';

export default function MainVideo() {
	const mainVideo = useRef();
	const [audioChecked, setAudioChecked] = useState(false);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [videoSource, setVideoSource] = useState();
	const [durationGATriggered, setDurationGATriggered] = useState(false);

	const playVideo = (e) => {
		triggerGAEvent('explore_choose-fun_v_play');
		setVideoPlaying(true);
	};

	const pauseVideo = () => {
		triggerGAEvent('explore_choose-fun_v_stop');
		setVideoPlaying(false);
	};

	const triggerVideo = (e) => {
		if (e.code === 'Enter' || e.type === 'click') {
			if (!videoPlaying) {
				playVideo();
			} else {
				pauseVideo();
			}
		}
	};

	const handleProgress = (e) => {
		if (e.played * 100 > 50) {
			if (!durationGATriggered) {
				triggerGAEvent('explore_choose-fun_v_50%_complete');
				setDurationGATriggered(true);
			}
		}
	};

	const resetGATrigger = () => {
		audioChecked ? setAudioChecked(false) : setAudioChecked(true);

		if (durationGATriggered) {
			setDurationGATriggered(false);
		}
	};

	useEffect(() => {
		setVideoSource(
			audioChecked
				? 'https://player.vimeo.com/video/820554888?h=ffff22ff83&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
				: 'https://player.vimeo.com/video/820554102?h=f9432f2e9b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
		);
	}, [audioChecked]);

	return (
		<section
			id="section-video"
			className={`z-30 relative panel ${
				!videoPlaying && 'bkg-gradient-bottom'
			}`}
			data-tag="Video"
		>
			<div
				className={`absolute top-0 left-0 w-full h-[100%] flex flex-col justify-center items-center font-black pb-24 z-10 transition ease-out duration-150  ${
					videoPlaying ? 'opacity-0' : 'opacity-100'
				}`}
				onClick={triggerVideo}
				style={{
					backgroundImage: `url(${mainPosterVideo})`,
					backgroundSize: 'cover',
				}}
			>
				<p className="uppercase font-black text-tempo text-[40px] leading-[44px] lg:text-[50px] text-center text-shadow px-3">
					Choose fun like never&nbsp;before on Carnival&nbsp;Jubilee
					<sup className="text-[16px] -top-[20px] md:-top-[28px]">™</sup>
				</p>
				<span
					role="button"
					tabIndex={0}
					aria-label="Play Carnival Jubilee featured video"
					className="material-symbols-rounded text-[138px] hover:opacity-50 cursor-pointer"
				>
					play_circle
				</span>
			</div>
			<div
				className={
					'absolute left-[2%] bottom-[7%] z-20 cursor-pointer grid grid-cols-2 items-center'
				}
			>
				<img className="w-[60px]" src={iconAD} alt="audio description icon" />
				<ToggleSwitch
					tabIndex={0}
					checked={audioChecked}
					onChange={resetGATrigger}
					ariaLabel={'Turn audio description ' + (audioChecked ? 'off' : 'on')}
				/>
			</div>
			<div
				className={
					'relative w-full h-full top-0 left-0 cursor-pointer  ' +
					(videoPlaying ? 'z-10' : 'z-0 ')
				}
			>
				<div className="player-wrapper pointer-events-none">
					<ReactPlayer
						ref={mainVideo}
						className="pointer-events-auto react-player"
						volume={1}
						playing={videoPlaying}
						controls={true}
						url={videoSource}
						width="100%"
						height="100%"
						onPlay={playVideo}
						onPause={pauseVideo}
						onEnded={() => triggerGAEvent('explore_choose-fun_v_complete')}
						onProgress={handleProgress}
					/>
				</div>
			</div>
		</section>
	);
}
