/** @format */
import './App.css';
import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { triggerGAEvent } from './helpers';

import carnivalWhite from './assets/images/carnival-white.svg';
import carnivalLogo from './assets/images/carnival.png';
import carnivalLogo2x from './assets/images/carnival@2x.png';
import jubileeLogo from './assets/images/jubilee.png';
import jubileeLogo2x from './assets/images/jubilee@2x.png';
import shipImage from './assets/images/ship.webp';
import headerVideoCaptions from './assets/Intro.vtt';
import oceanVideoPoster from './assets/BookNow-Poster.jpg';

import Preloader from './Preloader';
import Video from './common/Video';
import DecisionTree from './DecisionTree/DecisionTree';
import MainVideo from './common/Vimeo.jsx';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

if (typeof window !== `undefined`) {
	gsap.registerPlugin(ScrollTrigger, SplitText);
	ScrollTrigger.normalizeScroll(false);
}

function App() {
	const mainRef = useRef();
	const headerSpan = useRef();
	const headerLogo = useRef();
	const headerCaption = useRef();
	const headerCTA = useRef();
	const headerVideoRef = useRef();
	const meetRef = useRef();
	const [pageLoaded, setPageLoaded] = useState(false);
	const [allVideosPlaying, setAllVideosPlaying] = useState(true);
	const [zoomFeature, setZoomFeature] = useState(false);
	const [hideSail, setHideSail] = useState(false);
	const [windowHeight, setWindowHeight] = useState();
	const [docHeight, setDocHeight] = useState();
	const [trackLength, setTracklength] = useState();
	const [zoomWidth, setZoomWidth] = useState();

	const headerTl = useRef();
	const funTl = useRef();
	const destinationTl = useRef();

	let isMobile =
		window.matchMedia('(max-width: 992px)').matches ||
		window.matchMedia('(pointer: coarse)').matches;

	const headerVideo = !isMobile
		? require(`./assets/Intro.mp4`)
		: require(`./assets/Intro-M.mp4`);

	const headerVideoPoster = !isMobile
		? require(`./assets/Intro-Poster.jpg`)
		: require(`./assets/Intro-M-Poster.jpg`);

	const oceanVideo = !isMobile
		? require(`./assets/BookNow.mp4`)
		: require(`./assets/BookNow-M.mp4`);

	const interactAllVideos = (e) => {
		if (e.code === 'Enter' || e.type === 'click') {
			if (!allVideosPlaying) {
				setAllVideosPlaying(true);
				const videos = document.querySelectorAll('video');
				const mainVideo = document.getElementById('main-video');
				videos.forEach((video) => {
					if (video !== mainVideo) {
						video.play();
					}
				});
			} else {
				setAllVideosPlaying(false);
				const videos = document.querySelectorAll('video');

				videos.forEach((video) => {
					video.pause();
				});
			}
		}
	};

	const sendToFloodlightExplore = () => {
		window.gtag('event', 'conversion', {
			allow_custom_scripts: true,
			send_to: 'DC-10750860/carni0/carni03h+standard',
		});
	};

	const sendToFloodlightShop = () => {
		window.gtag('event', 'conversion', {
			allow_custom_scripts: true,
			send_to: 'DC-10750860/carni0/carni03i+standard',
		});
	};

	useEffect(() => {
		const startScreen = () => {
			setPageLoaded(true);
			ScrollTrigger.refresh(true);
			headerVideoRef.current.play();
			headerTl.current.play();
		};

		const preloader = setTimeout(() => {
			startScreen();
		}, 3500);

		if (window.location.hash) {
			let hash = window.location.hash.substring(1);
			if (pageLoaded) {
				scrollTo(hash);
			} // hash found
		}
		return () => {
			clearTimeout(preloader);
		};
	}, [pageLoaded]);

	useEffect(() => {
		if (!zoomFeature) {
			document.body.classList.remove('animations-disabled');
			ScrollTrigger.enable();
		} else {
			if (zoomWidth > 350) {
				document.body.classList.add('animations-disabled');
				ScrollTrigger.killAll(true);
			}
		}

		const checkZoomFeature = () => {
			setZoomWidth(((window.outerWidth - 10) / window.innerWidth) * 100);

			zoomWidth > 195 ? setZoomFeature(true) : setZoomFeature(false);
		};

		let sections = document.querySelectorAll('.panel');
		let config = {
			root: null,
			rootMargin: '0px 0px -50% 0px',
			threshold: 0,
		};
		//trigger GA for each section
		const onChange = (changes, observer) => {
			changes.forEach((change) => {
				if (change.intersectionRatio > 0) {
					const dataTag = change.target.getAttribute('data-tag');

					triggerGAEvent(dataTag);

					observer.unobserve(change.target);
				}
			});
		};

		const observer = new IntersectionObserver(onChange, config);
		sections.forEach((section) => observer.observe(section));

		checkZoomFeature();
		window.addEventListener('resize', setZoomFeature, false);

		return () => {
			window.removeEventListener('resize', setZoomFeature);
			observer.disconnect();
		};
	}, [zoomFeature, zoomWidth]);

	useEffect(() => {
		const getDocHeight = () => {
			var D = document;
			return Math.max(
				D.body.scrollHeight,
				D.documentElement.scrollHeight,
				D.body.offsetHeight,
				D.documentElement.offsetHeight,
				D.body.clientHeight,
				D.documentElement.clientHeight
			);
		};

		const getMeasurements = () => {
			setWindowHeight(window.innerHeight);
			setDocHeight(getDocHeight());
			setTracklength(docHeight - windowHeight);
		};

		let scroll = {
			quarter: false,
			half: false,
			threeQuarters: false,
			full: false,
		};

		const amountScrolled = () => {
			let scrollTop = window.scrollY;
			let userScrolled = Math.floor((scrollTop / trackLength) * 100);

			if (!scroll.quarter && userScrolled > 25) {
				scroll.quarter = true;
				//console.log('25% scrolled!');
				triggerGAEvent('page-scroll-25');
			}

			if (!scroll.half && userScrolled > 50) {
				scroll.half = true;
				//console.log('50% scrolled!');
				triggerGAEvent('page-scroll-50');
			}

			if (!scroll.threeQuarters && userScrolled > 75) {
				scroll.threeQuarters = true;
				// console.log('75% scrolled!');
				triggerGAEvent('page-scroll-75');
			}

			if (!scroll.full && userScrolled > 98) {
				scroll.full = true;
				// console.log('100% scrolled!');
				triggerGAEvent('page-scroll-100');
			}
		};

		if (pageLoaded) {
			getMeasurements();
			window.addEventListener('scroll', amountScrolled);
		}
		return () => {
			window.removeEventListener('scroll', amountScrolled, false);
		};
	}, [pageLoaded, windowHeight, docHeight, trackLength]);

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			headerTl.current = gsap
				.timeline({})
				.to(headerSpan.current, {
					height: '34px',
					ease: 'ease-in',
				})
				.fromTo(
					headerLogo.current,
					{
						translateY: -20,
						opacity: 0,
					},
					{
						translateY: 10,
						opacity: 1,
						ease: 'ease-in',
					}
				)
				.to(headerCaption.current, {
					transformOrigin: '100% 100%',
					className: 'animate',
					ease: 'ease-in',
				})
				.to(headerCTA.current, {
					opacity: 1,
					transformOrigin: '100% 100%',
					delay: 0.75,
					ease: 'ease-in',
				});

			headerTl.current.pause();

			// section 2
			const mySplitText = new SplitText('#chars-fun', { type: 'chars' });
			const chars = mySplitText.chars;

			funTl.current = gsap
				.timeline({
					scrollTrigger: {
						id: 'fun',
						trigger: '#section-fun',
						start: 'top 30%',
						// markers: true,
					},
				})
				.fromTo(
					'#heading-experience span:first-child',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					chars,
					{
						opacity: 0,
						y: 20,
						scale: 3,
						stagger: 0.01,
					},
					{
						y: 0,
						scale: 1,
						duration: 0.7,
						opacity: 1,
						stagger: {
							each: 0.05,
							from: 'center',
						},
						ease: 'back',
					}
				)
				.fromTo(
					'#heading-experience span:last-child',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					'#body-experience',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					'#fun-cta',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				);

			const textDest = new SplitText('#chars-destination', { type: 'chars' });
			const char = textDest.chars;

			destinationTl.current = gsap
				.timeline({
					scrollTrigger: {
						id: 'fun',
						trigger: '#section-destination',
						start: 'top 30%',
						// markers: true,
					},
				})
				.fromTo(
					'#heading-destination span:first-child',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					char,
					{
						opacity: 0,
						y: 20,
						scale: 3,
						stagger: 0.01,
					},
					{
						y: 0,
						scale: 1,
						duration: 0.5,
						opacity: 1,
						stagger: {
							each: 0.07,
							from: 'center',
						},
					}
				)
				.fromTo(
					'#heading-destination span:last-child',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					'#body-destination',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				)
				.fromTo(
					'#destination-cta',
					{
						opacity: 0,
					},
					{
						opacity: 1,
						transformOrigin: '100% 100%',
					}
				);
		}, mainRef);

		return () => ctx.revert();
	}, []);

	const scrollTo = (section) => {
		const el = document.getElementById(section);
		el.setAttribute('tabindex', 0);
		el.focus();
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		});
	};

	return (
		<div className={pageLoaded ? '' : 'overflow-hidden h-screen'}>
			{!pageLoaded && <Preloader />}
			<a className="skip-main" href="#main">
				Skip to main content
			</a>
			<header
				className={
					'fixed z-[60] top-0 left-0 w-full py-6 px-4 items-center lg:px-[44px] lg:py-[24px] flex justify-between'
				}
			>
				<a
					href="/"
					tabIndex={0}
					aria-label="Carnival Meet Jubilee Home Page"
					onClick={() => triggerGAEvent('nav_t_logo')}
				>
					<img
						aria-hidden
						className={'flex-none w-[97px] h-[23px] md:w-[153px] md:h-[37px]'}
						src={carnivalLogo}
						alt="Carnival Cruise Line Logo"
						srcSet={carnivalLogo2x + ' 2x'}
					/>
				</a>

				<a
					aria-label="Explore sailings for Carnival Jubilee"
					href="https://www.carnival.com/cruise-search?shipcode=JB&icid=icp_explr_shp_jb_042523_tdlp_exploresailings"
					onClick={() => [
						triggerGAEvent('nav_t_explore'),
						sendToFloodlightExplore(),
					]}
				>
					<div
						className={
							'btn border border-white rounded-md bg-carnival-blue font-bold px-4 py-2 flex items-center hover:opacity-75 cursor-pointer transition duration-300 ease-out ' +
							(hideSail ? 'opacity-0' : 'opacity-100')
						}
						role="button"
					>
						Explore Sailings
					</div>
				</a>
			</header>
			<main id="main" className="App" ref={mainRef}>
				<section
					id="section-intro"
					className={'bkg-gradient first panel relative'}
					ref={meetRef}
					data-tag="Meet"
				>
					<h1
						className={
							'z-10 text-2xl uppercase font-bold text-white text-center text-openSans '
						}
					>
						<span className={'block h-[34px]'}>
							<span
								className={'block h-0 overflow-hidden text-shadow'}
								ref={headerSpan}
							>
								Meet the all-new
							</span>
						</span>
						<img
							ref={headerLogo}
							src={jubileeLogo}
							className={'block px-3 lg:px-0 mx-auto'}
							alt="Carnival Jubilee logo"
							srcSet={jubileeLogo2x + ' 2x'}
						/>
						<span id="header-caption" ref={headerCaption}>
							<span className={'text-[26px] md:text-3xl text-shadow'}>
								The Best Vacation
								<span className="block md:inline-block">
									&nbsp;You’ve Ever Had&nbsp;
								</span>
								is sailing your way!
							</span>
						</span>
					</h1>
					<div
						ref={headerCTA}
						className={
							'absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px] opacity-0'
						}
					>
						<p className="text-shadow"> Check it out </p>
						<div
							aria-hidden="true"
							focusable="false"
							className="motion-safe:animate-bounce mt-5"
						>
							<span
								className={
									'material-symbols-rounded block rotate-90 md:text-6xl pl-1 pr-4 '
								}
							>
								keyboard_double_arrow_right
							</span>
						</div>
					</div>
					<div
						className={'absolute w-full h-full top-0 left-0'}
						style={{ zIndex: -1 }}
					>
						<video
							ref={headerVideoRef}
							id="header-video"
							className="header-video"
							width="100%"
							height="100%"
							muted
							loop
							preload="auto"
							playsInline
							style={{ height: '100%' }}
							poster={headerVideoPoster}
						>
							<source src={headerVideo} type="video/mp4" />
							<track
								src={headerVideoCaptions}
								label="English Captions"
								kind="captions"
								srcLang="en-us"
								default
							/>
							Your browser does not support the video tag.
						</video>
					</div>
					<div
						id="media-controls"
						className="absolute right-16 bottom-16 md:right-16 md:bottom-16"
					>
						<span aria-hidden className="absolute aria-hidden mt-2 top-0">
							{allVideosPlaying ? 'Pause' : 'Play'} all videos
						</span>
						<div
							tabIndex={0}
							role="button"
							onKeyDown={interactAllVideos}
							onClick={interactAllVideos}
							aria-label={(allVideosPlaying ? 'Pause' : 'Play') + ' all videos'}
							className={'absolute top-0 left-0 block'}
						>
							{!allVideosPlaying && (
								<span
									aria-hidden
									className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-5xl"
								>
									play_circle
								</span>
							)}
							{allVideosPlaying && (
								<span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-5xl">
									pause_circle
								</span>
							)}
						</div>
					</div>
				</section>
				<section
					id="section-fun"
					className="bkg-gradient panel"
					data-tag="World Class"
				>
					<h2
						id="heading-experience"
						className={'heading text-shadow'}
						aria-label="Experience World-Class Fun On Board"
					>
						<span className="text-shadow" aria-hidden>
							Experience
						</span>
						<span aria-hidden id="chars-fun">
							World-Class Fun
						</span>
						<span className="text-shadow" aria-hidden>
							On Board
						</span>
					</h2>
					<div
						id="body-experience"
						className={
							'md:text-[21px] max-w-[825px] mx-auto text-center md:my-6 px-3 md:px-0 text-shadow'
						}
					>
						On Carnival Jubilee™, six fun-filled Zones are waiting for you –
						with delicious dining, enchanting entertainment, and endless ways to
						make your vacation&nbsp;awesome.
					</div>
					<div
						id="fun-cta"
						className={
							'absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px]'
						}
					>
						<div
							aria-hidden="true"
							focusable="false"
							className="motion-safe:animate-bounce"
						>
							<span
								className={
									'className="motion-safe:animate-bounce material-symbols-rounded block rotate-90 md:text-6xl pl-1 pr-4'
								}
							>
								keyboard_double_arrow_right
							</span>
						</div>
					</div>
					<div
						className={'absolute w-full h-full top-0 left-0'}
						style={{ zIndex: -1 }}
					>
						<Video
							videosplaying={allVideosPlaying}
							autoplay={true}
							name={'WorldClassFun'}
							activity={false}
						/>
					</div>
				</section>
				<section
					id="section-destination"
					className={'bkg-gradient panel'}
					data-tag="Unforgettable"
				>
					<h2
						aria-label="Explore Unforgettable Destinations"
						id="heading-destination"
						className={'heading text-shadow'}
					>
						<span aria-hidden>Explore</span>
						<span aria-hidden id="chars-destination">
							Unforgettable
						</span>
						<span aria-hidden>Destinations</span>
					</h2>
					<div
						id="body-destination"
						className={
							'text-[15px] md:text-[21px] max-w-[825px] mx-auto text-center px-3 md:px-0 md:my-6'
						}
					>
						Set sail for the coolest hotspots in the Western Caribbean and
						vacation the Carnival<sup>&reg;</sup> way. (That means a whole lot
						of fun).
					</div>
					<div
						id="destination-cta"
						className={
							'absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px]'
						}
					>
						<div
							aria-hidden="true"
							focusable="false"
							className="motion-safe:animate-bounce"
						>
							<span
								className={
									'className="motion-safe:animate-bounce material-symbols-rounded block rotate-90 md:text-6xl pl-1 pr-4'
								}
							>
								keyboard_double_arrow_right
							</span>
						</div>
					</div>
					<div
						className={'absolute w-full h-full top-0 left-0'}
						style={{ zIndex: -1 }}
					>
						<Video autoplay={true} name={'Destinations'} activity={false} />
					</div>
				</section>

				<DecisionTree
					pageLoaded={pageLoaded}
					videosplaying={allVideosPlaying}
					hideSail={hideSail}
					setHideSail={setHideSail}
					gsap={gsap}
					ScrollTrigger={ScrollTrigger}
				/>

				<MainVideo />

				<section
					id="section-booking"
					className={'overflow-x-hidden panel last '}
					data-tag="Ready to Sail"
				>
					<img
						src={jubileeLogo}
						className={'block mt-[130px] px-3 md:px-0 md:mt-0 md:mb-48 z-[2]'}
						alt="Carnival Jubilee logo"
						srcSet={jubileeLogo2x + ' 2x'}
					/>

					<div className="text-center relative z-10 mt-[200px] md:mt-0">
						<p className={'heading md:mb-4'}>Ready to sail?</p>
						<div className="max-w-[825px] mx-auto text-center md:mb-10">
							Carnival Jubilee™ sets sail in December, but you can check out
							special offers right now!&nbsp;
							<span className="lg:block">
								Shop for your Carnival
								<sup>&reg;</sup> cruise today and be one of the first to sail on
								this awesome new ship.
							</span>
						</div>
						<a
							aria-label="Shop for your Carnival cruise today"
							href="https://www.carnival.com/cruise-search?shipcode=JB&icid=icp_explr_shp_jb_042523_tdlp_readytosailcta"
							onClick={() => [
								triggerGAEvent('shop-now'),
								sendToFloodlightShop(),
							]}
						>
							<div className={'button hover:bg-red-800 cursor-pointer'}>
								Shop Now
							</div>
						</a>
					</div>

					<div
						className={
							'bkg-gradient-bottom absolute w-full h-full top-0 left-0'
						}
					>
						<img
							id="ship-image"
							className={
								'flex-none absolute top-[62%] left-[100%] md:inset-[50%] mx-auto z-[2] w-[500px] md:w-[747px] h-auto scale-125 md:scale-100'
							}
							src={shipImage}
							alt="Carnival Jubilee on the water with blue sky with some white clouds"
						/>
						<video
							id="oceanVideo"
							className="ocean-video"
							width="100%"
							height="100%"
							muted
							loop
							preload="meta"
							autoPlay
							playsInline
							poster={oceanVideoPoster}
						>
							<source src={oceanVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				</section>
			</main>

			<footer
				id="footer"
				className={
					' bg-carnival-blue p-6 mx-auto grid md:flex flex-col items-center justify-center'
				}
			>
				<img
					className={'flex-none mb-2 mx-auto'}
					src={jubileeLogo}
					alt="Carnival Jubilee Logo"
					srcSet={jubileeLogo + ' 2x'}
					style={{ width: '331px' }}
				/>

				<p className="block uppercase font-bold text-center text-tempo text-[28px] leading-[29px] md:text-[32px]">
					Sailing the western caribbean&nbsp;from Galveston in
					December&nbsp;2023
				</p>

				<nav id="footer-nav" aria-label="Main">
					<div
						aria-label="Website links"
						role="group"
						className="flex flex-col md:flex-row justify-start md:justify-center items-start md:items-center w-full md:w-[800px] max-w-[828px] mx-auto text-left md:text-center mt-6 h-auto"
					>
						<div
							tabIndex={0}
							role="link"
							onClick={() => {
								scrollTo('section-intro');
								triggerGAEvent('nav_f_meet');
							}}
							className="p-2 md:p-4 md:px-6 md:border-r md:border-white/25 text-center cursor-pointer hover:underline"
						>
							Meet Carnival Jubilee™
						</div>
						<div
							tabIndex={0}
							role="link"
							onClick={() => {
								scrollTo('decision-tree');
								triggerGAEvent('nav_f_choose');
							}}
							className="p-2 md:p-4 md:px-10  md:border-r md:border-white/25 text-center cursor-pointer hover:underline"
						>
							Choose Fun Like Never Before
						</div>
						<div
							tabIndex={0}
							role="link"
							aria-label="Watch the Video"
							onClick={() => {
								scrollTo('section-video');
								triggerGAEvent('nav_f_watch');
							}}
							className="p-2 md:p-4 md:px-6 text-center cursor-pointer hover:underline"
						>
							Watch the Video
						</div>
					</div>
				</nav>

				<div className="h-[1px] w-full my-6 ">
					<hr style={{ backgroundColor: 'white', opacity: 0.25 }} />
				</div>

				<div className="self-start grid md:flex w-full">
					<a
						href="https://www.carnival.com?icid=icp_explr_shp_jb_042523_tdlp_carnivallogo"
						aria-label="Go to Carnival home page"
						tabIndex={0}
						onClick={() => triggerGAEvent('nav_f_logo')}
					>
						<img
							className={'flex-none mb-2 p-2'}
							src={carnivalWhite}
							alt="Carnival Choose Fun Logo"
							style={{ width: '215px', height: '68px' }}
						/>
					</a>

					<div className="mr-2 pl-4 md:pl-10 pt-5 md:border-l border-white/25 text-[13px] flex md:items-center md:justify-center">
						&copy; 2023 Carnival Corporation.
						<br /> All rights reserved.
					</div>
					<div className="block md:flex flex-grow flex-row items-end justify-start ml-2 md:ml-8 mt-2 md:mt-0">
						<a
							className="text-[13px] m-2 mb-4 hover:underline"
							href="https://www.carnival.com/about-carnival/legal-notice.aspx"
							onClick={() => triggerGAEvent('nav_f_legal')}
						>
							Legal Notices
						</a>
						<a
							className="text-[13px] mb-4 ml-6 hover:underline"
							href="https://www.carnival.com/about-carnival/legal-notice/privacy-notice.aspx"
							onClick={() => triggerGAEvent('nav_f_privacy')}
						>
							Privacy & Cookies
						</a>
					</div>

					<div
						tabIndex={0}
						role="button"
						onKeyDown={(e) => {
							if (e.code !== 'Tab') {
								scrollTo('section-intro');
							}
						}}
						onClick={() => scrollTo('section-intro')}
						className="place-self-end rounded-full bg-white/50 hover:bg-white/25 cursor-pointer px-1 pt-1"
						aria-label="Go to top of page"
					>
						<span
							aria-hidden="true"
							focusable="false"
							className="material-symbols-rounded text-carnival-blue/50"
						>
							keyboard_arrow_up
						</span>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default App;
