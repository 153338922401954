/** @format */

import React, { useRef } from 'react';

const ToggleSwitch = ({ checked, onChange, tabIndex, ariaLabel }) => {
	const toggleRef = useRef();
	const changeEventonEnter = (e) => {
		if (e.code === 'Enter' || e.type === 'click') {
			toggleRef.current.click();
		}
	};

	return (
		<div className="toggle-switch" tabIndex={0} onKeyDown={changeEventonEnter}>
			<input
				ref={toggleRef}
				type="checkbox"
				tabIndex={tabIndex}
				className="toggle-switch-checkbox"
				name="toggleSwitch"
				id="toggleSwitch"
				checked={checked}
				aria-label={ariaLabel}
				onChange={(e) => onChange(e.target.checked)}
			/>
			<label
				className="toggle-switch-label"
				htmlFor="toggleSwitch"
				aria-live="assertive"
			>
				<span className="sr-only">{ariaLabel}</span>
				<span className="toggle-switch-inner" aria-hidden />
				<span className="toggle-switch-switch" />
			</label>
		</div>
	);
};

export default ToggleSwitch;
