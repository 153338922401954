/** @format */
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from '../Image';
import Video from '../Video';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Gallery.css';

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';

export default function Gallery({ items, videosplaying }) {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const swiperRef = useRef();
	const [galleryPaused, setGalleryPaused] = useState(false);

	const GalleryItem = ({ asset }) => {
		if (asset.type === 'image') {
			return <Image name={asset.assetUrl} alt={asset.altText} />;
		}

		if (asset.type === 'video') {
			return (
				<Video
					autoplay={!galleryPaused}
					name={asset.assetUrl}
					videoplaying={videosplaying}
				/>
			);
		}
	};

	const pauseGallery = () => {
		setGalleryPaused(true);
		swiperRef.current.autoplay.pause();
	};

	const playGallery = () => {
		setGalleryPaused(false);
		swiperRef.current.autoplay.resume();
	};

	return (
		<>
			<Swiper
				centeredSlides={true}
				autoplay={{
					delay: 3500,
					disableOnInteraction: false,
				}}
				loop={true}
				pagination={{
					clickable: true,
					type: 'bullets',
					renderBullet: function (index, className) {
						return (
							'<span aria-label="Go to Slide ' +
							index +
							'" tabindex="0" role="button" class="' +
							className +
							'">' +
							'<em>' +
							index +
							'</em>' +
							'<i></i>' +
							'<b></b>' +
							'</span>'
						);
					},
				}}
				effect={'fade'}
				a11y={{
					enabled: true,
				}}
				//navigation={true}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={(swiper) => {
					swiperRef.current = swiper;
				}}
				modules={[Autoplay, Pagination, Navigation, EffectFade]}
				className="mySwiper"
			>
				{items.map((asset, idx) => {
					return (
						<SwiperSlide key={`Slide-${idx}`}>
							<GalleryItem asset={asset} />
						</SwiperSlide>
					);
				})}
			</Swiper>

			<div className="swiper-nav p-3 lg:pl-8 ">
				<div>
					<button
						onClick={() => swiperRef.current.slidePrev()}
						ref={navigationPrevRef}
					>
						<span aria-hidden className="material-symbols-rounded text-[28px]">
							chevron_left
						</span>
						<span className="sr-only">Previous Slide</span>
					</button>
					<button>
						<span
							role="button"
							onClick={pauseGallery}
							className={
								'material-symbols-rounded filled text-[28px] ' +
								(galleryPaused ? 'hidden' : 'block')
							}
						>
							pause
						</span>
						<span
							role="button"
							onClick={playGallery}
							className={
								'material-symbols-rounded filled text-[28px] ' +
								(galleryPaused ? 'block' : 'hidden')
							}
						>
							play_arrow
						</span>
					</button>
				</div>

				<button
					onClick={() => swiperRef.current.slideNext()}
					ref={navigationNextRef}
				>
					<span aria-hidden className="material-symbols-rounded text-[28px]">
						chevron_right
					</span>
					<span className="sr-only">Next</span>
				</button>
			</div>
		</>
	);
}
