/** @format */

export const triggerGAEvent = (lbl) => {
	//console.log(lbl);
	if (typeof gtag !== 'undefined') {

		window.gtag('event', lbl, {
			send_to: 'td',
		});
		
		window.gtag('event', lbl, {
			send_to: 'tdGa',
		});

		window.gtag('event', 'click', {
			category: 'navigation',
			event_category: 'navigation',
			event_label: lbl,
			value: lbl,
			send_to: ['carnival', 'tdUa'],
		});
	}
};

export const triggerGAPageViewEvent = (pageTitle) => {
	if (typeof gtag !== 'undefined') {
		window.gtag('event', 'page_view', {
			page_title: pageTitle,
			page_location: window.location.href + pageTitle + '-Choices',
			send_to: ['tdUa', 'tdGa', 'td', 'carnival'],
		});
	}
};
