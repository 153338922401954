/** @format */

export const branches = {
	currents: {
		currents: ['dr_inks', 'golden_mermaid', 'emerils'],
		dr_inks: ['cozumel', 'mahogany_bay', 'costa_maya'],
		golden_mermaid: ['cozumel', 'mahogany_bay', 'costa_maya'],
		emerils: ['cozumel', 'mahogany_bay', 'costa_maya'],
		cozumel: ['alchemy', 'rudis', 'spa'],
		mahogany_bay: ['playground', 'bonsai', 'punchliner'],
		costa_maya: ['seashore', 'grand_central', 'playground1'],
		alchemy: ['bonsai', 'grand_central', 'casual_eats'],
		rudis: ['alchemy', 'loft19', 'red_frog'],
		spa: ['famous_favorites', 'marina_bar', 'serenity_bar'],
		playground: ['rudis', 'grand_central', 'alchemy'],
		bonsai: ['piano_bar', 'punchliner', 'spa'],
		punchliner: ['bonsai', 'marina_bar', 'famous_favorites'],
		seashore: ['marina_bar', 'serenity_bar', 'fahrenheit'],
		grand_central: ['rudis', 'red_frog', 'casino'],
		playground1: ['alchemy', 'piano_bar', 'casual_eats'],
	},
	seashore: {
		seashore: ['marina_bar', 'casual_eats', 'bonsai'],
		marina_bar: ['cozumel', 'mahogany_bay', 'costa_maya'],
		casual_eats: ['cozumel', 'mahogany_bay', 'costa_maya'],
		bonsai: ['cozumel', 'mahogany_bay', 'costa_maya'],
		cozumel: ['grand_central', 'fahrenheit', 'dr_inks'],
		mahogany_bay: ['grand_central2', 'punchliner', 'emerils'],
		costa_maya: ['rudis', 'spa', 'currents'],
		grand_central: ['emerils', 'dr_inks', 'spa'],
		fahrenheit: ['golden_mermaid', 'serenity_bar', 'red_frog'],
		dr_inks: ['emerils', 'punchliner', 'grand_central'],
		grand_central2: ['rudis', 'golden_mermaid', 'dr_inks'],
		punchliner: ['famous_favorites', 'fahrenheit', 'casino'],
		emerils: ['dr_inks', 'grand_central', 'casino'],
		rudis: ['piano_bar', 'dr_inks', 'alchemy'],
		spa: ['golden_mermaid', 'grand_central', 'emerils'],
		currents: ['serenity_bar', 'dr_inks', 'rudis'],
	},
	playground: {
		playground: ['red_frog', 'famous_favorites', 'marina_bar'],
		red_frog: ['cozumel', 'mahogany_bay', 'costa_maya'],
		famous_favorites: ['cozumel', 'mahogany_bay', 'costa_maya'],
		marina_bar: ['cozumel', 'mahogany_bay', 'costa_maya'],
		cozumel: ['currents', 'grand_central', 'bonsai'],
		mahogany_bay: ['punchliner', 'seashore', 'emerils'],
		costa_maya: ['rudis', 'golden_mermaid', 'seashore2'],
		currents: ['dr_inks', 'emerils', 'punchliner'],
		grand_central: ['golden_mermaid', 'rudis', 'piano_bar'],
		bonsai: ['loft19', 'casino', 'dr_inks'],
		punchliner: ['emerils', 'fahrenheit', 'golden_mermaid'],
		seashore: ['golden_mermaid', 'rudis', 'grand_central'],
		emerils: ['dr_inks', 'alchemy', 'piano_bar'],
		rudis: ['serenity_bar', 'casino', 'golden_mermaid'],
		golden_mermaid: ['dr_inks', 'rudis', 'grand_central'],
		seashore2: ['bonsai', 'dr_inks', 'spa'],
	},
};

export const activities = [
	{
		key: 'dr_inks',
		type: 'bar',
		name: 'Dr.Inks Ph.D Bar',
		description:
			'Enjoy ocean-themed cocktails and conversations so deep you might need a snorkel! Let the hours and the schools of aquatic creatures glide by, here in the whimsical undersea home of the philosophical octopus, Dr.Inks, Ph.D., exclusively in Currents aboard Carnival Jubilee™.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_drinksphd#onboard-activities/2',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'DrInksPhDBar-Grid',
			altText: 'alt text',
		},

		media: [
			{
				type: 'video',
				assetUrl: 'DrInksPhDBar-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'DrInksPhDBar-2',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'golden_mermaid',
		type: 'bar',
		name: 'Golden Mermaid',
		description:
			'With a splash of her tail and the siren song of great live music, the Golden Mermaid calls guests into her undersea hideaway in Currents. This Carnival Jubilee™ exclusive bar and lounge is the perfect place to leave the surface world behind and discover intriguing craft cocktails in a chill, refined atmosphere.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_goldenmermaid#onboard-activities/5',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'GoldenMermaid-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'GoldenMermaid-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'GoldenMermaid-2',
				altText: 'this is an image asset',
			},
			{
				type: 'image',
				assetUrl: 'GoldenMermaid-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'emerils',
		type: 'restaurant',
		name: 'Emeril’s Bistro 717',
		description:
			'BAM! Carnival’s Chief Culinary Officer, Emeril Lagasse, has created a menu of kicked-up cuisine exclusively for guests aboard Carnival Jubilee™. Watch ocean life glide by as you savor every flavor in the elegantly casual atmosphere of this bistro beneath the sealine in the Currents zone.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_emerils#dining/1',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'EmerilsBistro717-Grid',
			altText: 'alt text',
		},

		media: [
			{
				type: 'video',
				assetUrl: 'EmerilsBistro717-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'EmerilsBistro717-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'EmerilsBistro717-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'marina_bar',
		type: 'bar',
		name: 'Marina Bar',
		description:
			'Stroll The Shores into the easygoing yacht club atmosphere of Marina Bar, exclusively aboard Carnival Jubilee™. This nautical nook is the perfect place to launch your afternoon or to sit and enjoy specialty coffees, chilled wines, and delightful cocktails until the sun sinks into the sea.',
		externalLink:
			'https://www.carnival.com/onboard/bars?shipCode=JB&icid=icp_explr_shp_jb_042523_tdlp_marinabar',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',

		grid: { type: 'video', assetUrl: 'MarinaBar-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'MarinaBar-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'MarinaBar-2',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'bonsai',
		type: 'restaurant',
		name: 'Bonsai Sushi',
		description:
			'With artisan-pruned bonsai trees all around you and the most delicious delicacies on your plate - you’ve never experienced sushi at sea like this. Make your reservation and enjoy sushi, sashimi, sake, soups, and desserts in this premium, affordable dining experience.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_bonsaisushi#dining/6',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		grid: {
			type: 'image',
			assetUrl: 'BonsaiSushi-Grid',
			altText: 'A single piece of a sushi roll on a plate',
		},
		media: [
			{
				type: 'image',
				assetUrl: 'BonsaiSushi-1',
				altText: 'Overhead view of the Bonsai Sushi dining space',
			},
			{
				type: 'image',
				assetUrl: 'BonsaiSushi-2',
				altText:
					'A view of the sushi bar in the background with tables with food on them in the foreground',
			},
			{
				type: 'image',
				assetUrl: 'BonsaiSushi-3',
				altText: 'A sushi boat sitting on a table',
			},
		],
	},
	{
		key: 'red_frog',
		type: 'bar',
		name: 'RedFrog Tiki Bar',
		description:
			"Hop up to this fan-favorite watering hole for a delicious array of South Pacific flavors in a double-decker poolside bar that's bigger and better than ever. Bring your appetite for fun and your thirst for Polynesian Punch. Grass skirt optional.",
		externalLink:
			'https://www.carnival.com/onboard/redfrog-tiki-bar?icid=icp_explr_shp_jb_042523_tdlp_redfrogtiki',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',
		grid: { type: 'video', assetUrl: 'RedFrog-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'RedFrog-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'RedFrog-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'RedFrog-3',
				altText: 'this is an image asset',
			},
		],
	},

	{
		key: 'cozumel',
		type: 'destination',
		name: 'Cozumel',
		description:
			'How can one island hold so much to do, see, and taste? We’re not sure, but you should come check it out – especially if you love sipping icy cocktails in the sun, snorkeling, ziplining, or just chilling with your toes in the sand. Explore the Ancient City Of Tulum and shop along the waterfront for something special to bring back aboard Carnival Jubilee™.',
		externalLink:
			'https://www.carnival.com/cruise-to/caribbean-cruises/cozumel-cruises?icid=icp_explr_shp_jb_042523_tdlp_cozumel',
		externalLinkText: 'Explore Carnival<sup>®</sup> Excursions in Cozumel',
		grid: { type: 'video', assetUrl: 'Cozumel-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'Cozumel-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Cozumel-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'Cozumel-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		id: 9,
		key: 'mahogany_bay',
		type: 'destination',
		name: 'Mahogany Bay',
		description:
			'Welcome to the Western Caribbean’s favorite hidden gem: Mahogany Bay on the island of Roatan. Swim the crystal clear waters and dive the lush coral reefs, or take a Jungle ATV Adventure. Make amazing memories, then make your way back aboard Carnival Jubilee™.',
		grid: {
			type: 'video',
			assetUrl: 'MahoganyBay-Grid',
			altText: 'alt text',
		},
		externalLink:
			'https://www.carnival.com/cruise-to/caribbean-cruises/mahogany-bay-cruises?icid=icp_explr_shp_jb_042523_tdlp_mahoganybay',
		externalLinkText: 'Explore Carnival<sup>®</sup> Excursions in Mahogany Bay',
		media: [
			{
				type: 'video',
				assetUrl: 'MahoganyBay-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'MahoganyBay-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'MahoganyBay-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'costa_maya',
		type: 'destination',
		name: 'Costa Maya',
		description:
			'Sail into all the action and serenity that Mexico’s colorful Caribbean Coast has to offer. Go on safari in a Jaguar Bus, explore ancient Mayan ruins, swim through cenotes and lagoons, or just relax on the Lazy River and the powder-white beaches.',
		grid: {
			type: 'video',
			assetUrl: 'CostaMaya-Grid',
			altText: 'video of Costa Maya',
		},
		externalLink:
			'https://www.carnival.com/cruise-to/caribbean-cruises/costa-maya-cruises?icid=icp_explr_shp_jb_042523_tdlp_costamaya',
		externalLinkText: 'Explore Carnival<sup>®</sup> Excursions in Costa Maya',
		media: [
			{
				type: 'video',
				assetUrl: 'CostaMaya-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'CostaMaya-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'CostaMaya-3',
				altText: 'this is an image asset',
			},
		],
	},

	{
		key: 'rudis',
		type: 'restaurant',
		name: 'Rudi’s Seagrill',
		description:
			'Experience the playful styling and seriously delicious flavors of renowned Master Chef Rudi Sodamin. If you love seafood, make sure your vacation aboard Carnival Jubilee™ includes a meal in this effortlessly classy restaurant.',
		grid: {
			type: 'video',
			assetUrl: 'RudisSeagrill-Grid',
			altText: 'alt text',
		},
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_rudis#dining/3',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		media: [
			{
				type: 'video',
				assetUrl: 'RudisSeagrill-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'RudisSeagrill-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'RudisSeagrill-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'spa',
		type: 'activity',
		name: 'Cloud 9 Spa',
		description:
			'Ready to take relaxation at sea to a deeper level? Unwind and rejuvenate from head to toe, let yourself be pampered with a facial treatment, a body wrap, or some time in our Thermal Suite.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_cloud9spa#onboard-activities/9',
		externalLinkText: 'Discover more world-class fun on Carnival Jubilee™',
		grid: { type: 'image', assetUrl: 'Cloud9Spa-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'Cloud9Spa-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Cloud9Spa-2',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'loft19',
		type: 'activity',
		name: 'Loft 19',
		description:
			'Who says fun can’t be luxurious and indulgent? Definitely not you. Loft 19™ is a peaceful, retreat-style experience with its own infinity whirlpool and shady, comfortable cabanas where you can enjoy some shade and privacy. You won’t want to miss it!',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_loft19#staterooms/9',
		externalLinkText: 'Discover more world-class fun on Carnival Jubilee™',
		grid: { type: 'video', assetUrl: 'Loft19-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'Loft19-1',
				altText: 'this is a video asset',
			},
			{
				type: 'image',
				assetUrl: 'Loft19-2',
				altText: 'Friends meeting up at Loft 19 to enjoy the fresh air',
			},
			{
				type: 'image',
				assetUrl: 'Loft19-3',
				altText: 'Lounge chairs for guests to relax in at Loft 19',
			},
		],
	},
	{
		key: 'serenity_bar',
		type: 'activity',
		name: 'Serenity Adults Only Retreat',
		description:
			'Send the kids off to Camp Ocean for the day, then find your way to Serenity: the Adult Only retreat within Carnival Jubilee™. Here you can leave the noise behind, slip into the hot tub, and let your cares drift away on the ocean breeze. Recharged and reconnected, you’re ready to step back into the action.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_serenity#onboard-activities/8',
		externalLinkText: 'Discover more world-class fun on Carnival Jubilee™',
		grid: { type: 'video', assetUrl: 'Serenity-Grid', altText: 'alt text' },

		media: [
			{
				type: 'video',
				assetUrl: 'Serenity-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Serenity-2',
				altText: 'this is an image asset',
			},
			{
				type: 'image',
				assetUrl: 'Serenity-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'playground',
		type: 'zone',
		name: 'Ultimate Playground',
		description:
			'Like the name says, this is Carnival Jubilee™’s ultimate place to turn it up, splash down, and go all out! Roar through the sunny sky on Bolt™ The Ultimate SeaCoaster™, share a round of mini-golf, or splash into excitement for all ages at WaterWorks.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_ultimateplayground#zones/6',
		externalLinkText: 'Learn more about Ultimate Playground',
		grid: {
			type: 'video',
			assetUrl: 'UltimatePlayground-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'playground1',
		type: 'zone',
		name: 'Ultimate Playground',
		description:
			'Like the name says, this is Carnival Jubilee™’s ultimate place to turn it up, splash down, and go all out! Roar through the sunny sky on Bolt™ The Ultimate SeaCoaster™, share a round of mini-golf, or splash into excitement for all ages at WaterWorks.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_ultimateplayground#zones/6',
		externalLinkText: 'Learn more about Ultimate Playground',
		grid: {
			type: 'video',
			assetUrl: 'UltimatePlayground-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'UltimatePlayground-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'punchliner',
		type: 'activity',
		name: 'Punchliner Comedy Club',
		description:
			'It’s serious funny business at the Punchliner Comedy Club™, where you’ll find professional comics who know how to wedge a little laughter into anyone’s evening. There are laughs for all ages and senses of humor, with family-friendly and adult-only shows to choose from.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_punchliner#onboard-activities/10',
		externalLinkText: 'Discover more world-class fun on Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'PunchlinerComdeyClub-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'PunchlinerComdeyClub-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'PunchlinerComdeyClub-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'PunchlinerComdeyClub-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'piano_bar',
		type: 'bar',
		name: 'Piano Bar 88',
		description:
			'At Piano Bar 88, you call the shots. So pull up a seat, order your favorite cocktail and let your friendly piano player know what you’d like to hear. And since the bar’s open from the evening until late at night, it’s the perfect spot for an early drink or singing ‘til the wee hours.',
		externalLink:
			'https://www.carnival.com/onboard/piano-bar?icid=icp_explr_shp_jb_042523_tdlp_pianobar',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',
		grid: { type: 'video', assetUrl: 'PianoBar-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'PianoBar-1',
				altText: 'this is a video asset',
			},
			{
				type: 'image',
				assetUrl: 'PianoBar-2',
				altText: 'this is an image asset',
			},
			{
				type: 'image',
				assetUrl: 'PianoBar-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'fahrenheit',
		type: 'restaurant',
		name: 'Fahrenheit 555',
		description:
			'Ready for a truly unforgettable meal? The finest cuts of beef and the most fantastic seafood are prepared to perfection in this premium dining experience. Make your reservation, and make your surf and turf dreams come true.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_steakhouse#dining/8',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		grid: {
			type: 'image',
			assetUrl: 'Fahrenheit555-Grid',
			altText: 'Ahi Tuna Tartare dish',
		},
		media: [
			{
				type: 'image',
				assetUrl: 'Fahrenheit555-1',
				altText:
					'Steak with butter, garlic, garnish, and tomato in a iron cast dish',
			},
			{
				type: 'image',
				assetUrl: 'Fahrenheit555-2',
				altText: 'Chef picking up ice cube with tongs',
			},
			{
				type: 'image',
				assetUrl: 'Fahrenheit555-3',
				altText: 'A view of the dining room',
			},
		],
	},
	{
		key: 'casino',
		type: 'activity',
		name: 'Casino',
		description:
			'Feeling lucky? At sea, there’s no better way to try your hand and seek your fortune than stepping up to the exciting slots and table games of the Carnival Jubilee™ Casino. Joining the Carnival Players Club® can get you great offers and invitations to exclusive events.',
		externalLink:
			'https://www.carnival.com/onboard/casino?shipCode=JB&icid=icp_explr_shp_jb_042523_tdlp_casino',
		externalLinkText: 'Discover more world-class fun on Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'Casino-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'Casino-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Casino-2',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'currents',
		type: 'zone',
		name: 'Currents',
		description:
			'Inspired by sea life and the call of the waves, here you’ll chill at Dr.Inks Ph.D. Bar, catch live music at The Golden Mermaid, and savor the kicked-up flavor of Emeril’s Bistro 717. This transformative Zone is exclusive to Carnival Jubilee™.',
		grid: {
			type: 'video',
			assetUrl: 'Currents-Grid',
			altText: 'alt text',
		},
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_currents#zones/2',
		externalLinkText: 'Explore Currents',
		media: [
			{
				type: 'video',
				assetUrl: 'Currents-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Currents-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'Currents-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'seashore',
		type: 'zone',
		name: 'The Shores',
		description:
			'Land and waters meet in The Shores, a laid-back Zone inspired by seaside charm and beach life– exclusively on Carnival Jubilee™. Enjoy exceptional seafood at the famous Cucina del Capitano, or find all-new boardwalk favorites like Coastal Slice and Beach Buns. Then relax with specialty coffees or chilled wines at Marina Bar.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_theshores#zones/3',
		externalLinkText: 'Discover The Shores',
		grid: { type: 'video', assetUrl: 'Seashores-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'Seashores-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Seashores-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'Seashores-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'seashore2',
		type: 'zone',
		name: 'The Shores',
		description:
			'Land and waters meet in The Shores, a laid-back Zone inspired by seaside charm and beach life– exclusively on Carnival Jubilee™. Enjoy exceptional seafood at the famous Cucina del Capitano, or find all-new boardwalk favorites like Coastal Slice and Beach Buns. Then relax with specialty coffees or chilled wines at Marina Bar.',
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_theshores#zones/3',
		externalLinkText: 'Discover The Shores',
		grid: { type: 'video', assetUrl: 'Seashores-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'Seashores-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'Seashores-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'Seashores-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'casual_eats',
		type: 'casual_dining',
		name: 'Awesome Casual Eats',
		description:
			'Hungry? Thirsty? Of course you are! Grab a premium coffee at JavaBlue Cafe and let your sweet tooth lead the way to Cherry on Top. Or head over to The Shores and check out fan favorites like burgers at Beach Buns or authentic brick oven pizza at Coastal Slice.',
		externalLink:
			'https://www.carnival.com/cruise-food/?shipCode=JB&icid=icp_explr_shp_jb_042523_tdlp_casualeats',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'AwesomeCasualEats-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'AwesomeCasualEats-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'AwesomeCasualEats-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'AwesomeCasualEats-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'grand_central',
		type: 'activity',
		name: 'Grand Central',
		description:
			'Welcome to a Zone full of music, spectacular talent, and iconic spaces like the Grand View Bar and Center Stage bar. The Atrium theater is the heart of the Grand Central, and every seat in the house is perfect for you to enjoy the dazzling array of live entertainment aboard Carnival Jubilee™.',
		grid: {
			type: 'video',
			assetUrl: 'GrandCentral-Grid',
			altText: 'alt text',
		},
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_grandcentral#zones/1',
		externalLinkText: 'Discover more world-class fun on Carnival Jubille™',
		media: [
			{
				type: 'video',
				assetUrl: 'GrandCentral-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'GrandCentral-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'GrandCentral-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'grand_central2',
		type: 'activity',
		name: 'Grand Central',
		description:
			'Welcome to a Zone full of music, spectacular talent, and iconic spaces like the Grand View Bar and Center Stage bar. The Atrium theater is the heart of the Grand Central, and every seat in the house is perfect for you to enjoy the dazzling array of live entertainment aboard Carnival Jubilee™.',
		grid: {
			type: 'video',
			assetUrl: 'GrandCentral-Grid',
			altText: 'alt text',
		},
		externalLink:
			'https://www.carnival.com/cruise-ships/carnival-jubilee?icid=icp_explr_shp_jb_042523_tdlp_grandcentral#zones/1',
		externalLinkText: 'Discover more world-class fun on Carnival Jubille™',
		media: [
			{
				type: 'video',
				assetUrl: 'GrandCentral-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'GrandCentral-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'GrandCentral-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'alchemy',
		type: 'bar',
		name: 'Alchemy Bar',
		description:
			'Explore the science and craft of cocktail creation at Alchemy Bar®. At our unique vintage-themed cocktail venue, master mixologists are happy to show off their skills and treat you to tasty, refreshing surprises.',
		externalLink:
			'https://www.carnival.com/onboard/alchemy-bar?icid=icp_explr_shp_jb_042523_tdlp_alchemybar',
		externalLinkText: 'Explore bars & lounges aboard Carnival Jubilee™',
		grid: { type: 'image', assetUrl: 'AlchemyBar-Grid', altText: 'alt text' },
		media: [
			{
				type: 'video',
				assetUrl: 'AlchemyBar-1',
				altText: 'this is a video asset',
			},
			{
				type: 'image',
				assetUrl: 'AlchemyBar-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'AlchemyBar-3',
				altText: 'this is an image asset',
			},
		],
	},
	{
		key: 'famous_favorites',
		type: 'casual_dining',
		name: 'Famous Favorites',
		description:
			"Only Carnival lets you sail into food choices this awesome!  Set a course for Flavortown with BBQ and craft beers at Guy's Pig & Anchor Smokehouse|Brewhouse or bite into the perfect patty at Guy's Burger Joint. Or savor Shaq's signature fried chicken sandwiches, strips, and baskets at Big Chicken™!",
		externalLink:
			'https://www.carnival.com/cruise-food/?shipCode=JB&icid=icp_explr_shp_jb_042523_tdlp_famousfavs',
		externalLinkText: 'Explore dining aboard Carnival Jubilee™',
		grid: {
			type: 'video',
			assetUrl: 'FamousFavorites-Grid',
			altText: 'alt text',
		},
		media: [
			{
				type: 'video',
				assetUrl: 'FamousFavorites-1',
				altText: 'this is a video asset',
			},
			{
				type: 'video',
				assetUrl: 'FamousFavorites-2',
				altText: 'this is an image asset',
			},
			{
				type: 'video',
				assetUrl: 'FamousFavorites-3',
				altText: 'this is an image asset',
			},
		],
	},
];
