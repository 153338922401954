/** @format */

import React from 'react';

const Image = ({ name, alt }) => {
	try {
		let isMobile =
			window.matchMedia('(max-width: 992px)').matches ||
			window.matchMedia('(pointer: coarse)').matches;

		// Import image on demand
		const image = !isMobile
			? require(`../assets/activities/${name}.jpg`)
			: require(`../assets/activities/${name}-M.jpg`);
		const image2x = !isMobile
			? require(`../assets/activities/${name}@2x.jpg`)
			: require(`../assets/activities/${name}-M@2x.jpg`);
		// If the image doesn't exist. return null

		if (!image) return null;
		return (
			<img
				src={image}
				srcSet={image2x + ' 2x, ' + image + ' 600w, ' + image2x + ' 1300w'}
				alt={alt}
			/>
		);
	} catch (error) {
		console.log(`Image with name "${name}" does not exist`);
		return null;
	}
};

export default Image;
