/** @format */

import React, { useEffect, useRef } from 'react';

import './Video.css';

const Video = ({
	name,
	autoplay = false,
	activity = true,
	videoplaying = true,
}) => {
	const videoRef = useRef();

	useEffect(() => {
		// if (videoplaying) {
		// 	videoRef.current.play();
		// } else {
		// 	videoRef.current.pause();
		// }
	}, [videoplaying]);

	try {
		let isMobile =
			window.matchMedia('(max-width: 992px)').matches ||
			window.matchMedia('(pointer: coarse)').matches;

		// Import image on demand
		const path = activity ? 'activities/' : '';
		const video = !isMobile
			? require(`../assets/${path}${name}.mp4`)
			: require(`../assets/${path}${name}-M.mp4`);

		const poster = !isMobile
			? require(`../assets/${path}${name}-Poster.jpg`)
			: require(`../assets/${path}${name}-M-Poster.jpg`);

		const captions = require(`../assets/${path}${name}.vtt`);

		if (!video) return null;

		const playVideo = (event) => {
			if (!isMobile && videoplaying) {
				event.target.play();
			}
		};

		if (autoplay && videoplaying) {
			return (
				<div
					className="h-full"
					dangerouslySetInnerHTML={{
						__html: `
				<video
				ref=${videoRef}
					width="100%"
					height="100%"
					autoplay loop muted playsinline
					preload="meta"
					poster=${poster}

				>
					<source src=${video + '#t=0.1'} type="video/mp4" />
					<track src="${captions}" label="English Captions" kind="captions" srclang="en-us" default />

					Your browser does not support the video tag.
				</video>
				 `,
					}}
				></div>
			);
		} else {
			return (
				<video
					ref={videoRef}
					id={'video' + name}
					onMouseOver={playVideo}
					className={name}
					width="100%"
					height="100%"
					muted
					loop
					preload="meta"
					poster={poster}
					src={video}
				>
					<source src={video} type="video/mp4" />
					<track
						src={captions}
						label="English Captions"
						kind="captions"
						srcLang="en-us"
						default
					/>
					Your browser does not support the video tag.
				</video>
			);
		}
	} catch (error) {
		console.log(error);
		console.log(`Video with name "${name}" does not exist`);

		return null;
	}
};

export default Video;
