/** @format */
import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
// import _ from 'lodash';
import './DecisionTree.css';
import { triggerGAEvent, triggerGAPageViewEvent } from '../helpers';
import { branches, activities } from '../data';
import Gallery from '../common/Gallery/Gallery.jsx';
import Video from '../common/Video';
import Image from '../common/Image';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

export default function DecisionTree({
	videosplaying,
	setHideSail,
	pageLoaded,
}) {
	const [branch, setBranch] = useState('');
	const [branchSelected, isBranchSelected] = useState(false);
	const [stepCount, setStepCount] = useState(0);
	const [selectedChoices, setSelectedChoiceArr] = useState([]);
	const [choice1Selected, isChoice1Selected] = useState(false);
	const [choice2Selected, isChoice2Selected] = useState(false);
	const [choice3Selected, isChoice3Selected] = useState(false);
	const [choice4Selected, isChoice4Selected] = useState(false);
	const treeRef = useRef();
	const [isMobile, setIsMobile] = useState(false);
	const treeTl = useRef();
	// const [showSailPerm, setShowSailPerm] = useState(false);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		let initialChoice = queryParams.get('d');
		if (
			pageLoaded &&
			initialChoice &&
			['currents', 'the-shores', 'playground'].includes(initialChoice)
		) {
			if (initialChoice === 'the-shores') {
				initialChoice = 'seashore';
			}
			setBranch(initialChoice);
			isBranchSelected(true);
			setHideSail(false);
			triggerGAEvent('1_' + initialChoice);

			//scroll to the tree
			treeRef.current.scrollIntoView({
				// behavior: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		}
	}, [setBranch, setHideSail, pageLoaded]);

	useEffect(() => {
		let page = {
			Level1: false,
			Level2: false,
			Level3: false,
			Level4: false,
			Level5: false,
		};

		const triggerPageView = () => {
			const choiceBottom = document
				.getElementById('decision-tree')
				.getBoundingClientRect().bottom;

			const GAPageTag = document
				.getElementById('decision-tree')
				.lastChild.getAttribute('data-tag');

			if (
				choiceBottom <
				document.getElementById('decision-tree').getBoundingClientRect()
					.height /
					2
			) {
				if (!page.Level1 && GAPageTag === 'Level 1') {
					page.Level1 = true;
					triggerGAPageViewEvent(GAPageTag);
				}

				if (!page.Level2 && GAPageTag === 'Level 2') {
					page.Level2 = true;
					triggerGAPageViewEvent(branch + '-' + GAPageTag);
				}

				if (!page.Level3 && GAPageTag === 'Level 3') {
					page.Level3 = true;
					triggerGAPageViewEvent(branch + '-' + GAPageTag);
				}

				if (!page.Level4 && GAPageTag === 'Level 4') {
					page.Level4 = true;
					triggerGAPageViewEvent(branch + '-' + GAPageTag);
				}

				if (!page.Level5 && GAPageTag === 'Level 5') {
					page.Level5 = true;
					triggerGAPageViewEvent(branch + '-' + GAPageTag);
				}
			}

			if (choiceBottom < 0) {
				//when tree is over
				setHideSail(false);
			}
		};

		window.addEventListener('scroll', triggerPageView, true);

		return () => {
			window.removeEventListener('scroll', triggerPageView, true);
		};
	}, [branch, setHideSail]);

	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			const textDest = new SplitText('#section-choice1>p span', {
				type: 'chars',
			});
			const char = textDest.chars;

			treeTl.current = gsap
				.timeline({
					scrollTrigger: {
						id: 'choice',
						trigger: treeRef.current,
						start: 'top 30%',
					},
				})
				.to('div.choice-item', {
					ease: 'none',

					scrollTrigger: {
						id: 'choice',
						trigger: treeRef.current,
						toggleClass: { targets: 'div.choice-item', className: 'animate' },
						//scrub: 0.35,
						start: 'center 100%',
					},
				})
				.fromTo(
					char,
					{
						opacity: 0,
						y: 90,
						stagger: 0.01,
					},
					{
						y: 0,
						duration: 0.7,
						opacity: 1,
						stagger: {
							each: 0.05,
						},
					}
				)
				.to('#section-choice1 .choice-headline', {
					ease: 'power3.out',
					scrollTrigger: {
						id: 'choice-heading',
						trigger: treeRef.current,
						start: 'center 100%',
						delay: 3,
						toggleClass: {
							targets: '#section-choice1 .choice-headline',
							className: 'animate',
						},
					},
				})
				.fromTo(
					'#section-choice1 .choice-heading',
					{ opacity: 0 },
					{
						ease: 'power3.out',
						opacity: 1,

						scrollTrigger: {
							// id: 'choice-heading',
							trigger: treeRef.current,
							start: 'center 100%',

							toggleClass: {
								targets: '#section-choice1 .choice-heading',
								className: 'animate',
							},
						},
					}
				)
				.fromTo(
					'#section-choice1 .button',
					{ opacity: 0 },
					{
						ease: 'power3.out',
						opacity: 1,
						scrollTrigger: {
							id: 'choice-heading',
							trigger: treeRef.current,
							start: 'center 35%',
						},
					}
				);
		}, treeRef);

		setIsMobile(
			window.matchMedia('(max-width: 992px)').matches ||
				window.matchMedia('(pointer: coarse)').matches
		);

		return () => ctx.revert();
	}, [isMobile]);

	const scrollTo = () => {
		const el = document.getElementById('decision-tree');
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		});
		setHideSail(false);
	};

	const restartDecision = (e) => {
		if (e.code === 'Enter' || e.type === 'click') {
			setBranch('');
			isBranchSelected(false);
			setStepCount(0);
			setSelectedChoiceArr([]);
			isChoice1Selected(false);
			isChoice2Selected(false);
			isChoice3Selected(false);
			isChoice4Selected(false);
			ScrollTrigger.refresh(true);
			triggerGAEvent(branch + '_restart');
			setHideSail(false);
		}
	};

	function playVideo(event) {
		if (!isMobile) {
			const video = event.target
				.querySelector('.grid-choice-bkg')
				.querySelector('video');

			if (video && videosplaying) {
				video.play();
			}
		}
	}

	function pauseVideo(event) {
		if (!isMobile) {
			const video = event.target
				.querySelector('.grid-choice-bkg')
				.querySelector('video');

			if (video && videosplaying) {
				video.pause();
			}
		}
	}

	function SelectBranch({ selected, name }) {
		const handleClick = (e) => {
			if (e.code === 'Enter' || e.type === 'click') {
				setBranch(selected);
				isBranchSelected(true);
				triggerGAEvent('1_' + selected);
				scrollTo();
				//extend the tree
				//turn sail btn on
				setHideSail(false);
			}
		};

		const gridItem = activities.find((activity) => activity.key === selected);

		if (branchSelected) {
			const currentChoice = activities.find(
				(activity) => activity.key === branch
			);

			if (selected === branch) {
				return (
					<div
						tabIndex={0}
						className={
							'choice-item selected h-full w-full flex items-end lg:mb-4 relative'
						}
					>
						{selected === branch && (
							<div
								className={
									'media-container absolute top-0 left-0 w-full h-full z-0'
								}
							>
								<Gallery
									videosplaying={videosplaying}
									items={currentChoice.media}
								/>
							</div>
						)}
						<div
							className={
								'relative z-20 md:w-1/2 text-left p-3 lg:pl-10 mb-20 lg:mb-10 pointer-events-none'
							}
						>
							<h2
								className={
									'block text-[32px] leading-[44px] lg:text-[68px] lg:leading-[70px] font-bold mb-3 text-left ' +
									(currentChoice.key === 'currents' ? 'logo-currents' : '') +
									(currentChoice.key === 'seashore' ||
									currentChoice.key === 'seashore2'
										? ' logo-seashore'
										: '')
								}
							>
								{currentChoice.name}
							</h2>
							{currentChoice.description}
							<a
								href={currentChoice.externalLink}
								onClick={() => triggerGAEvent('Carnival_' + currentChoice.name)}
								className={'my-4 block hover:underline pointer-events-auto'}
							>
								{currentChoice.externalLinkText + ' »'}
							</a>
						</div>
						<p
							className={
								'scroll-indicator absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px] pointer-events-none '
							}
						>
							Scroll to choose more!
							<span
								aria-hidden="true"
								focusable="false"
								className={
									'material-symbols-rounded block rotate-90 md:text-3xl pl-1 pr-4'
								}
							>
								keyboard_double_arrow_right
							</span>
						</p>
					</div>
				);
			} else {
				return (
					<div
						tabIndex={0}
						aria-expanded="false"
						role="button"
						data-tag={'switch_' + name}
						className={'secondary-choices'}
						onClick={handleClick}
						onKeyDown={handleClick}
					>
						{name}
					</div>
				);
			}
		} else {
			return (
				//non selected
				<>
					<div
						tabIndex={0}
						role="button"
						aria-expanded="false"
						className={
							'choice-item border h-[25%] lg:h-full w-full flex flex-col items-center justify-center lg:justify-end cursor-pointer relative overflow-hidden'
						}
						onKeyDown={handleClick}
						onClick={handleClick}
						onMouseEnter={playVideo}
						onMouseLeave={pauseVideo}
						data-tag={name}
						aria-label={'Explore ' + name}
					>
						<div className={'grid-choice-bkg h-full'}>
							<Video
								name={gridItem.grid.assetUrl}
								alt={gridItem.grid.altText}
							/>
						</div>
						<div
							className={
								'relative z-20 p-3 lg:pl-10 lg:pb-10 flex flex-col lg:flex-row w-full lg:block items-end text-center'
							}
						>
							<p
								tabIndex={-1}
								className={
									'choice-heading font-black uppercase text-[32px] lg:text-4xl text-tempo w-[150px] md:w-auto mx-auto text-center md:text-center leading-[31px] lg:leading-auto lg:ml-auto'
								}
							>
								{name}
							</p>
							<div
								tabIndex={-1}
								aria-hidden
								aria-label={'Explore ' + name}
								className={'button mb-0 mx-auto'}
							>
								Explore
							</div>
						</div>
					</div>
				</>
			);
		}
	}

	function RenderBranch({ branch }) {
		function setPath(event) {
			if (event.code === 'Enter' || event.type === 'click') {
				const activityKey = event.target.getAttribute('data-key');

				if (!choice1Selected) {
					setStepCount(stepCount + 1);

					//set to the overall array
					setSelectedChoiceArr([...selectedChoices, activityKey]);
					isChoice1Selected(true);
					triggerGAEvent(branch + '_' + (stepCount + 2) + '_' + activityKey);
					scrollTo('section-choice2');
					setHideSail(false);
				} else {
					// in the event they decide to choose anothero ption
					if (activityKey) {
						const currArr = [...selectedChoices];
						currArr.splice(0, 1, activityKey);
						setSelectedChoiceArr(currArr);
						triggerGAEvent(
							branch + '_' + (stepCount + 1) + '_switch_' + activityKey
						);
					}
				}
			}
		}

		if (branchSelected) {
			const choices = Object.entries(branches[branch])[0][1];

			const selections = activities.filter((activity) =>
				choices.includes(activity.key)
			);

			var ChoiceItems = selections.map((choice) => (
				<div
					tabIndex={0}
					role="button"
					className={
						'choice-item w-full border cursor-pointer ' +
						(selectedChoices.includes(choice.key)
							? 'h-full absolute left-0 top-0 z-20 pointer-events-none selected'
							: 'h-[25%] lg:h-full relative z-10 justify-center ') +
						(choice1Selected && !selectedChoices.includes(choice.key)
							? 'secondary-choices z-20 border-0'
							: ' ')
					}
					key={choice.key}
					data-key={choice.key}
					onClick={setPath}
					onKeyDown={setPath}
					onMouseEnter={playVideo}
					onMouseLeave={pauseVideo}
					aria-label={'Explore ' + choice.name}
				>
					{selectedChoices.includes(choice.key) && (
						<div
							className={
								'media-container absolute top-0 left-0 w-full h-full z-0 ' +
								(selectedChoices.includes(choice.key) &&
								selectedChoices.length === 1
									? 'visible'
									: 'hidden')
							}
						>
							<Gallery videosplaying={videosplaying} items={choice.media} />
						</div>
					)}
					<div
						className={
							'relative w-full h-full flex flex-col lg:justify-end pointer-events-none mb-10 ' +
							(choice1Selected && !selectedChoices.includes(choice.key)
								? 'justify-center pb-0'
								: 'justify-end pb-6 lg:pb-7')
						}
					>
						<div
							className={
								'grid-choice-bkg ' +
								(selectedChoices.includes(choice.key) ? 'hidden' : 'visible') +
								(choice1Selected && !selectedChoices.includes(choice.key)
									? ' hidden'
									: '')
							}
						>
							{choice.grid.type === 'video' ? (
								<Video name={choice.grid.assetUrl} alt={choice.grid.altText} />
							) : (
								<Image name={choice.grid.assetUrl} alt={choice.grid.altText} />
							)}
						</div>
						<div
							className={
								'relative z-20 p-3 lg:pl-10 lg:pb-10 ' +
								(selectedChoices.includes(choice.key)
									? 'flex-col md:w-1/2 pb-14 '
									: '') +
								(choice1Selected && !selectedChoices.includes(choice.key)
									? 'alternative '
									: 'flex flex-col lg:flex-row w-full lg:block items-end ')
							}
						>
							<p
								className={
									'choice-heading w-[150px] md:w-full text-[32px] lg:text-4xl uppercase font-bold ' +
									(selectedChoices.includes(choice.key)
										? 'text-left text-[32px] leading-[44px] lg:text-[68px] lg:leading-[70px] w-full'
										: 'mx-auto text-center ') +
									(selectedChoices.includes(choice.key) &&
									choice.key === 'currents'
										? 'logo-currents'
										: '') +
									((selectedChoices.includes(choice.key) &&
										choice.key === 'seashore') ||
									(selectedChoices.includes(choice.key) &&
										choice.key === 'seashore2')
										? ' logo-seashore'
										: '')
								}
							>
								{choice.name}
							</p>
							<div
								aria-hidden="true"
								focusable="false"
								aria-label={'Explore ' + choice.name}
								className={
									'button mb-0 mx-auto ' + (choice1Selected ? 'none' : '')
								}
							>
								Explore
							</div>
							<div
								className={
									selectedChoices.includes(choice.key)
										? 'visible text-left text-[15px] leading-[18px] md:text-[17px] md:leading-[23px]'
										: 'hidden'
								}
							>
								{choice.description}
								<a
									href={choice.externalLink}
									onClick={() => triggerGAEvent('Carnival_' + choice.name)}
									className={'my-4 block hover:underline pointer-events-auto'}
								>
									{choice.externalLinkText} »
								</a>
							</div>
						</div>

						<p
							className={
								'scroll-indicator absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px] pointer-events-none ' +
								(selectedChoices.includes(choice.key) ? 'block' : 'hidden')
							}
						>
							Scroll to choose more!
							<span
								aria-hidden="true"
								focusable="false"
								className={
									'material-symbols-rounded block rotate-90 md:text-3xl pl-1 pr-4'
								}
							>
								keyboard_double_arrow_right
							</span>
						</p>
					</div>
				</div>
			));
		}

		if (branchSelected) {
			return (
				<div className={'flex h-screen items-center border justify-center '}>
					<div
						role="group"
						tabIndex={0}
						className={
							'flex flex-col justify-end lg:flex-row lg:justify-between items-center h-full w-full text-center relative'
						}
					>
						{ChoiceItems}
					</div>
				</div>
			);
		}
	}

	function RenderChoice({ branch }) {
		if (branchSelected) {
			const choice = selectedChoices.slice(0, 1);

			const choiceKeys = Object.entries(branches[branch]).find((e) =>
				e[0].includes(choice)
			);

			const choiceSelections = activities.filter((activity) =>
				choiceKeys[1].includes(activity.key)
			);

			function captureChoice(event) {
				if (event.code === 'Enter' || event.type === 'click') {
					scrollTo();

					const activityKey = event.target.getAttribute('data-key');

					if (!choice2Selected) {
						setStepCount(stepCount + 1);
						isChoice2Selected(true);
						//set to the overall array
						setSelectedChoiceArr([...selectedChoices, activityKey]);
						isChoice2Selected(true);
						triggerGAEvent(branch + '_' + (stepCount + 2) + '_' + activityKey);
						setHideSail(false);
					} else {
						// in the event they decide to choose anothero ption
						if (activityKey) {
							const currArr = [...selectedChoices];
							currArr.splice(1, 1, activityKey);
							setSelectedChoiceArr(currArr);
							triggerGAEvent(
								branch + '_' + (stepCount + 1) + '_switch_' + activityKey
							);
						}
					}
				}
			}

			const selections = choiceSelections.map((d) => (
				<div
					tabIndex={0}
					role="button"
					className={
						'choice-item w-full border cursor-pointer ' +
						(selectedChoices.includes(d.key)
							? 'pb-14 lg:pb-0 h-full absolute left-0 top-0 z-20 pointer-events-none selected'
							: 'h-[25%] lg:h-full relative z-10 justify-center ') +
						(choice2Selected && !selectedChoices.includes(d.key)
							? 'secondary-choices z-20 border-0'
							: ' ')
					}
					data-key={d.key}
					key={d.key}
					onClick={captureChoice}
					onKeyDown={captureChoice}
					onMouseEnter={playVideo}
					onMouseLeave={pauseVideo}
					aria-label={'Explore ' + d.name}
				>
					{selectedChoices.includes(d.key) && (
						<div
							className={
								'media-container absolute top-0 left-0 w-full h-full z-0 ' +
								(selectedChoices.includes(d.key) && selectedChoices.length === 2
									? 'visible'
									: 'hidden')
							}
						>
							<Gallery videosplaying={videosplaying} items={d.media} />
						</div>
					)}
					<div
						className={
							'relative w-full h-full flex flex-col pointer-events-none mb-10 ' +
							(choice2Selected && !selectedChoices.includes(d.key)
								? 'justify-center pb-0'
								: 'justify-end lg:pb-10')
						}
					>
						<div
							className={
								'grid-choice-bkg ' +
								(selectedChoices.includes(d.key) ? 'hidden' : 'visible') +
								(choice2Selected && !selectedChoices.includes(d.key)
									? ' hidden'
									: '')
							}
						>
							{d.grid.type === 'video' ? (
								<Video name={d.grid.assetUrl} alt={d.grid.assetUrl} />
							) : (
								<Image name={d.grid.assetUrl} alt={d.grid.altText} />
							)}
						</div>
						<div
							className={
								'relative z-20 p-3 lg:pl-10 lg:pb-10 ' +
								(selectedChoices.includes(d.key)
									? 'flex-col md:w-1/2 pb-14 '
									: '') +
								(choice2Selected && !selectedChoices.includes(d.key)
									? 'alternative'
									: 'flex flex-col lg:flex-row w-full lg:block items-center lg:items-end ')
							}
						>
							<p
								className={
									'choice-heading text-[32px] md:w-full lg:text-3xl uppercase font-bold ' +
									(selectedChoices.includes(d.key)
										? 'text-left text-[32px] leading-[44px] lg:text-[68px] lg:leading-[70px] w-full'
										: 'text-center ') +
									(selectedChoices.includes(d.key) && d.key === 'currents'
										? 'logo-currents'
										: '') +
									((selectedChoices.includes(d.key) && d.key === 'seashore') ||
									(selectedChoices.includes(d.key) && d.key === 'seashore2')
										? ' logo-seashore'
										: '')
								}
							>
								{d.name}
							</p>
							<div
								aria-hidden="true"
								focusable="false"
								className={
									'button mb-4 md:mb-0 mx-auto ' +
									(choice2Selected ? 'none' : '')
								}
							>
								Explore
							</div>
							<div
								className={
									selectedChoices.includes(d.key)
										? 'visible text-left text-[15px] leading-[18px] md:text-[17px] md:leading-[23px]'
										: 'hidden'
								}
							>
								{d.description}
								<a
									href={d.externalLink}
									onClick={() => triggerGAEvent('Carnival_' + d.name)}
									className={'mt-4 block hover:underline pointer-events-auto'}
									dangerouslySetInnerHTML={{
										__html: `

									${d.externalLinkText} »`,
									}}
								></a>
							</div>
						</div>
					</div>
					<p
						className={
							'scroll-indicator absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px] pointer-events-none ' +
							(selectedChoices.includes(d.key) ? 'block' : 'hidden')
						}
					>
						Scroll to choose more!
						<span
							aria-hidden="true"
							focusable="false"
							className={
								'material-symbols-rounded block rotate-90 md:text-3xl pl-1 pr-4'
							}
						>
							keyboard_double_arrow_right
						</span>
					</p>
				</div>
			));

			if (stepCount > 0 && stepCount < 4) {
				return (
					<div className={'flex h-screen items-center border justify-center'}>
						<div
							role="group"
							tabIndex={0}
							className={
								'flex flex-col justify-end lg:flex-row lg:justify-between items-center h-full w-full text-center relative'
							}
						>
							{selections}
						</div>
					</div>
				);
			}
		}
	}

	function RenderChoice2({ choice }) {
		if (selectedChoices.length > 1) {
			const choice2 = choice.slice(1, 2);

			const choiceKeys = Object.entries(branches[branch]).find((e) =>
				e[0].includes(choice2)
			);

			const choiceSelections2 = activities.filter((activity) =>
				choiceKeys[1].includes(activity.key)
			);

			function captureChoice(event) {
				if (event.code === 'Enter' || event.type === 'click') {
					const activityKey = event.target.getAttribute('data-key');
					scrollTo();

					if (!choice3Selected) {
						//set the initial choice
						setStepCount(stepCount + 1);
						isChoice3Selected(true);
						setSelectedChoiceArr([...selectedChoices, activityKey]);
						triggerGAEvent(branch + '_' + (stepCount + 2) + '_' + activityKey);
						setHideSail(false);
					} else {
						//switch out
						if (activityKey) {
							const currArr = [...selectedChoices];
							currArr.splice(2, 1, activityKey);
							setSelectedChoiceArr(currArr);
							triggerGAEvent(
								branch + '_' + (stepCount + 1) + '_switch_' + activityKey
							);
						}
					}
				}
			}

			const selections = choiceSelections2.map((d) => (
				<div
					tabIndex={0}
					role="button"
					className={
						'choice-item w-full border cursor-pointer ' +
						(selectedChoices.includes(d.key)
							? 'pb-14 lg:pb-0 h-full absolute left-0 top-0 z-20 pointer-events-none selected '
							: 'h-[25%] lg:h-full relative z-10 justify-center ') +
						(choice3Selected && !selectedChoices.includes(d.key)
							? 'secondary-choices z-20 border-0'
							: ' ')
					}
					onClick={captureChoice}
					onKeyDown={captureChoice}
					onMouseEnter={playVideo}
					onMouseLeave={pauseVideo}
					data-key={d.key}
					key={d.key}
					aria-label={'Explore ' + d.name}
				>
					{selectedChoices.includes(d.key) && (
						<div
							className={
								'media-container absolute top-0 left-0 w-full h-full z-0 ' +
								(selectedChoices.includes(d.key) && selectedChoices.length === 3
									? 'visible'
									: 'hidden')
							}
						>
							<Gallery videosplaying={videosplaying} items={d.media} />
						</div>
					)}

					<div
						className={
							'relative w-full h-full flex flex-col pointer-events-none mb-10 ' +
							(choice3Selected && !selectedChoices.includes(d.key)
								? 'justify-center pb-0'
								: 'justify-end lg:pb-10')
						}
					>
						<div
							className={
								'grid-choice-bkg ' +
								(selectedChoices.includes(d.key) ? 'hidden' : 'visible') +
								(choice3Selected && !selectedChoices.includes(choice.key)
									? ' hidden'
									: '')
							}
						>
							{d.grid.type === 'video' ? (
								<Video name={d.grid.assetUrl} alt={d.grid.assetUrl} />
							) : (
								<Image name={d.grid.assetUrl} alt={d.grid.altText} />
							)}
						</div>
						<div
							className={
								'relative z-20 p-3 lg:pl-10 lg:pb-10 ' +
								(selectedChoices.includes(d.key)
									? 'flex-col md:w-1/2 pb-14 '
									: '') +
								(choice3Selected && !selectedChoices.includes(d.key)
									? 'alternative'
									: 'flex flex-col lg:flex-row w-full lg:block items-center lg:items-end ')
							}
						>
							<p
								className={
									'choice-heading w-[150px] md:w-full text-[32px] leading-[40px] lg:text-4xl uppercase font-bold ' +
									(selectedChoices.includes(d.key)
										? 'text-left text-[32px] leading-[44px] lg:text-[68px] lg:leading-[70px] w-full'
										: 'text-center ') +
									(selectedChoices.includes(d.key) && d.key === 'currents'
										? ' logo-currents'
										: '') +
									((selectedChoices.includes(d.key) && d.key === 'seashore') ||
									(selectedChoices.includes(d.key) && d.key === 'seashore2')
										? ' logo-seashore'
										: '')
								}
							>
								{d.name}
							</p>
							<div
								aria-hidden="true"
								focusable="false"
								className={
									'button mb-4 md:mb-0 mx-auto ' +
									(choice3Selected ? 'none' : '')
								}
							>
								Explore
							</div>
							<div
								className={
									selectedChoices.includes(d.key)
										? 'visible text-left text-[15px] leading-[18px] md:text-[17px] md:leading-[23px]'
										: 'hidden'
								}
							>
								{d.description}
								<a
									onClick={() => triggerGAEvent('Carnival_' + d.name)}
									className={'mt-4 block hover:underline pointer-events-auto'}
									href={d.externalLink}
								>
									{d.externalLinkText}&nbsp;»
								</a>
							</div>
						</div>
					</div>
					<p
						className={
							'scroll-indicator absolute bottom-0 left-0 right-0 margin-auto text-center uppercase font-bold md:text-[23px] pointer-events-none ' +
							(selectedChoices.includes(d.key) && selectedChoices.length === 3
								? 'block'
								: 'hidden')
						}
					>
						Scroll to choose more!
						<span
							aria-hidden="true"
							focusable="false"
							className={
								'material-symbols-rounded block rotate-90 md:text-3xl pl-1 pr-4'
							}
						>
							keyboard_double_arrow_right
						</span>
					</p>
				</div>
			));

			if (stepCount > 0 && stepCount < 4) {
				return (
					<div className={'flex h-screen items-center border justify-center'}>
						<div
							role="group"
							tabIndex={0}
							className={
								'flex flex-col justify-end lg:flex-row lg:justify-between items-center h-full w-full text-center relative'
							}
						>
							{selections}
						</div>
					</div>
				);
			}
		}
	}

	function RenderChoice3({ choice }) {
		if (selectedChoices.length > 2) {
			const choice3 = choice.slice(2, 3);

			const choiceKeys = Object.entries(branches[branch]).find((e) =>
				e[0].includes(choice3)
			);

			const choiceSelections3 = activities.filter((activity) =>
				choiceKeys[1].includes(activity.key)
			);

			function captureChoice(event) {
				if (event.code === 'Enter' || event.type === 'click') {
					const activityKey = event.target.getAttribute('data-key');
					scrollTo();

					if (!choice4Selected) {
						//set the initial selection
						setStepCount(stepCount + 1);
						isChoice4Selected(true);
						setSelectedChoiceArr([...selectedChoices, activityKey]);
						triggerGAEvent(branch + '_' + (stepCount + 2) + '_' + activityKey);
						setHideSail(false);
						// setShowSailPerm(true);
					} else {
						// in the event they decide to choose anothero ption
						if (activityKey) {
							const currArr = [...selectedChoices];
							currArr.splice(3, 1, activityKey);
							setSelectedChoiceArr(currArr);
							triggerGAEvent(
								branch + '_' + (stepCount + 1) + '_switch_' + activityKey
							);
						}
					}
				}
			}

			const selections = choiceSelections3.map((d) => (
				<div
					tabIndex={0}
					role="button"
					className={
						'choice-item w-full border cursor-pointer ' +
						(selectedChoices.includes(d.key)
							? 'pb-14 lg:pb-0 h-full absolute left-0 top-0 z-20 pointer-events-none selected '
							: 'h-[25%] lg:h-full relative z-10 justify-center ') +
						(choice4Selected && !selectedChoices.includes(d.key)
							? 'secondary-choices z-20 border-0'
							: ' ')
					}
					onClick={captureChoice}
					onKeyDown={captureChoice}
					onMouseEnter={playVideo}
					onMouseLeave={pauseVideo}
					data-key={d.key}
					key={d.key}
					aria-label={'Explore ' + d.name}
				>
					{selectedChoices.includes(d.key) && (
						<div
							className={
								'media-container absolute top-0 left-0 w-full h-full z-0 ' +
								(selectedChoices.includes(d.key) && selectedChoices.length === 4
									? 'visible'
									: 'hidden')
							}
						>
							<Gallery videosplaying={videosplaying} items={d.media} />
						</div>
					)}
					<div
						className={
							'relative w-full h-full flex flex-col md:justify-end pointer-events-none mb-10 ' +
							(choice4Selected && !selectedChoices.includes(d.key)
								? 'justify-center pb-0'
								: 'justify-end lg:pb-10')
						}
					>
						<div
							className={
								'grid-choice-bkg ' +
								(selectedChoices.includes(d.key) ? 'hidden' : 'visible') +
								(choice4Selected && !selectedChoices.includes(choice.key)
									? ' hidden'
									: '')
							}
						>
							{d.grid.type === 'video' ? (
								<Video name={d.grid.assetUrl} alt={d.grid.assetUrl} />
							) : (
								<Image name={d.grid.assetUrl} alt={d.grid.altText} />
							)}
						</div>
						<div
							className={
								'relative z-20 p-3 lg:pl-10 lg:pb-10 ' +
								(selectedChoices.includes(d.key)
									? 'flex-col md:w-1/2 pb-14 '
									: '') +
								(choice4Selected && !selectedChoices.includes(d.key)
									? 'alternative'
									: 'flex flex-col lg:flex-row w-full lg:block items-center lg:items-end ')
							}
						>
							<p
								className={
									'choice-heading w-[150px] md:w-full text-[32px] leading-[40px] lg:text-4xl uppercase font-bold ' +
									(selectedChoices.includes(d.key)
										? 'text-left text-[32px] leading-[44px] lg:text-[68px] lg:leading-[70px] w-full'
										: 'text-center ') +
									(selectedChoices.includes(d.key) && d.key === 'currents'
										? 'logo-currents'
										: '') +
									((selectedChoices.includes(d.key) && d.key === 'seashore') ||
									(selectedChoices.includes(d.key) && d.key === 'seashore2')
										? ' logo-seashore'
										: '')
								}
							>
								{d.name}
							</p>
							<div
								aria-hidden="true"
								focusable="false"
								className={
									'button mb-4 md:mb-0 mx-auto ' +
									(choice4Selected ? 'none' : '')
								}
							>
								Explore
							</div>
							<div
								className={
									selectedChoices.includes(d.key)
										? 'visible text-left text-[15px] leading-[18px] md:text-[17px] md:leading-[23px]'
										: 'hidden'
								}
							>
								{d.description}
								<a
									onClick={() => triggerGAEvent('Carnival_' + d.name)}
									className={'mt-4 block hover:underline pointer-events-auto'}
									href={d.externalLink}
								>
									{d.externalLinkText}&nbsp;»
								</a>

								<div
									className={
										'btn-restart border border-white/50 uppercase inline-block py-2 px-4 my-4 text-sm pointer-events-auto hover:bg-white/50'
									}
								>
									<div
										role="button"
										tabIndex={0}
										type="button"
										onKeyDown={restartDecision}
										onClick={restartDecision}
										className="flex justify-center items-center "
									>
										<span
											aria-hidden="true"
											focusable="false"
											className="material-symbols-rounded"
										>
											restart_alt
										</span>
										&nbsp;Restart to make new choices
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			));

			if (stepCount > 0 && stepCount < 5) {
				return (
					<div className={'flex h-screen items-center border justify-center'}>
						<div
							role="group"
							tabIndex={0}
							className={
								'flex flex-col justify-end lg:flex-row lg:justify-between items-center h-full w-full text-center relative'
							}
						>
							{selections}
						</div>
					</div>
				);
			}
		}
	}

	return (
		<>
			<section
				id="decision-tree"
				className="decision-tree panel"
				ref={treeRef}
				data-tag="Ready to choose"
			>
				{selectedChoices.length === 0 && (
					<div
						data-tag="Level 1"
						id="section-choice1"
						className={
							'choice-selections flex flex-col lg:flex-row justify-between h-screen items-center relative ' +
							(selectedChoices.length > 0 ? 'hidden' : 'visible')
						}
					>
						<p
							className={
								'choice-headline z-20 text-[40px] leading-[40px] lg:leading-[71px] lg:text-[67px] text-tempo uppercase absolute top-0 left-0 mx-auto w-full mt-[90px] lg:mt-[154px] text-center drop-shadow-lg text-shadow pointer-events-none ' +
								(branchSelected ? 'hidden' : 'block')
							}
						>
							<span
								className="block"
								aria-hidden="true"
								tabIndex={-1}
								focusable="false"
							>
								So...ready to
								<span className="block lg:inline-block"> choose fun?</span>
							</span>
							<span className="sr-only">So...ready to choose fun?</span>
						</p>
						<div
							role="group"
							className={
								'flex flex-col justify-end lg:justify-between lg:flex-row w-full h-full z-10'
							}
						>
							<SelectBranch selected={'currents'} name={'Currents'} />
							<SelectBranch selected={'seashore'} name={'The Shores'} />
							<SelectBranch
								selected={'playground'}
								name={'Ultimate Playground'}
							/>
						</div>
					</div>
				)}

				{branchSelected && selectedChoices.length < 2 && (
					<div
						data-tag="Level 2"
						id="section-choice2"
						className={
							'choice-selections block relative overflow-hidden ' +
							(selectedChoices.length > 1 ? 'hidden ' : 'visible ')
						}
					>
						<p
							className={
								'choice-headline text-[40px] leading-[40px] lg:leading-[71px] lg:text-[67px] text-tempo uppercase absolute top-0 left-0 mx-auto w-full mt-[110px] lg:mt-[154px] text-center z-20 pointer-events-none drop-shadow-md text-shadow ' +
								(branchSelected ? 'block' : 'block')
							}
						>
							Where to now?
						</p>
						<RenderBranch branch={branch} />
					</div>
				)}

				{branchSelected && selectedChoices.length < 3 && choice1Selected && (
					<div
						data-tag="Level 3"
						id="section-choice3"
						className={
							'choice-selections block relative overflow-hidden ' +
							(selectedChoices.length > 2 ? 'hidden' : 'visible')
						}
					>
						<p
							className={
								'choice-headline text-[40px] leading-[40px] lg:leading-[71px] lg:text-[67px] text-tempo uppercase absolute top-0 left-0 mx-auto w-full mt-[84px] lg:mt-[154px] text-center z-20 pointer-events-none drop-shadow-md text-shadow ' +
								(branchSelected ? 'block' : 'block')
							}
						>
							Explore breathtaking destinations
							<span className={'block text-openSans text-[20px] lg:-mt-8'}>
								Where to?
							</span>
						</p>
						<RenderChoice branch={branch} />
					</div>
				)}

				{branchSelected && selectedChoices.length < 4 && choice2Selected && (
					<div
						data-tag="Level 4"
						id="section-choice4"
						className={
							'choice-selections block relative overflow-hidden ' +
							(selectedChoices.length > 3 ? 'hidden' : 'visible')
						}
					>
						<p
							className={
								'choice-headline text-[40px] leading-[40px] lg:leading-[71px] lg:text-[67px] text-tempo uppercase absolute top-0 left-0 mx-auto w-full mt-[94px] lg:mt-[154px] text-center z-20 pointer-events-none drop-shadow-md text-shadow ' +
								(selectedChoices.length > 2 ? 'hidden' : 'block')
							}
						>
							There’s more where that&nbsp;came from!
							<span className={'block text-openSans text-[20px] lg:-mt-8'}>
								What’s next?
							</span>
						</p>
						<RenderChoice2 choice={selectedChoices} />
					</div>
				)}

				{branchSelected && selectedChoices.length >= 3 && (
					<div
						data-tag="Level 5"
						id="section-choice5"
						className={
							'choice-selections block relative overflow-hidden ' +
							(selectedChoices.length > 4 ? 'hidden' : 'visible')
						}
					>
						<p
							className={
								'choice-headline text-[40px] leading-[40px] lg:leading-[71px] lg:text-[67px] text-tempo uppercase absolute top-0 left-0 mx-auto w-full mt-[120px] lg:mt-[154px] text-center z-20 pointer-events-none drop-shadow-md text-shadow ' +
								(branchSelected ? 'block' : 'block')
							}
						>
							Ready for even more?
						</p>
						<RenderChoice3 choice={selectedChoices} />
					</div>
				)}
			</section>
		</>
	);
}
